import {Controller} from '@stimulus/core'

export default class FormController extends Controller {
    static targets = ['submit']
    submitTargets!: Array<HTMLButtonElement>

    connect() {
        this.verifySubmission()
    }

    verifySubmission() {
        if (this.canSubmit()) {
            this.enableSubmit()
        } else {
            this.disableSubmit()
        }
    }

    canSubmit(): boolean {
        return document.querySelectorAll('input[type="checkbox"]:checked').length > 0
    }

    enableSubmit() {
        this.submitTargets.forEach(target => target.removeAttribute('disabled'))
    }

    disableSubmit() {
        this.submitTargets.forEach(target => target.setAttribute('disabled', ''))
    }
}