import { Controller } from "@stimulus/core";

export default class ToggleController extends Controller {
  static get targets() {
    return ["toggleable"];
  }

  toggleableTargets!: HTMLElement[]
  data!: any

  trigger() {
    const classToToggle = this.data.get("class") || "is-active"
    this.toggleableTargets.forEach(el => el.classList.toggle(classToToggle));
  }
}
