import { Controller } from '@stimulus/core'

export default class NavController extends Controller {
  connect () {
    const url = this.element.getAttribute('href')

    /**
     * This keeps the Nav menu item highlighted for sub-menu pages while only highlighting the home menu item while on the home page
     */
    if (url.lastIndexOf('/') !== 0 && window.location.pathname.startsWith(url)) {
      this.element.classList.add('current-nav')
    } else if (url === window.location.pathname) {
      this.element.classList.add('current-nav')
    }
  }
}
